<template>
  <div class="content">
    <moreHeader :iconColor="1"></moreHeader>
    <h1>{{ data.title }}</h1>
    <div v-if="data.list" class="call">
      <div v-for="(item, i) in data.list" :key="i" class="callPolice">
        <img :src="item.img" alt="" />
        <div>
          <h3>{{ item.title }}</h3>
          <div>{{ item.text }}</div>
        </div>
      </div>
    </div>
    <p>{{ data.text }}</p>
    <img :src="data.img" alt="" />
    <div class="more">更多场景：</div>

    <div v-if="id != 0" class="moreScene" @click="go(0)">数据采集</div>
    <div v-if="id != 1" class="moreScene" @click="go(1)">能耗分析</div>
    <div v-if="id != 2" class="moreScene" @click="go(2)">报警管理</div>
    <div v-if="id != 3" class="moreScene" @click="go(3)">设备管理</div>
  </div>
</template>

<script>
import moreHeader from "../../../components/header.vue";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      id: null,
      data: {},
      data1: {
        title: "数据采集",
        text: "现场采集内容覆盖楼宇自控、门禁、智能空调、ups、电梯、 变配电、消防等系统。其中主要关注核心系统运行状况、主要 能耗管网状态、环境介质质量监测等数据。将全楼宇的智能控 制系统的实时状态采集进入系统，供数据监视、存储、报警、 分析、计算、统计平衡等使用。",
        img: require("../../../assets/assets/buildingImg/img2.png"),
      },
      data2: {
        title: "能耗分析",
        img: require("../../../assets/assets/buildingImg/img6.png"),
        text: "通过能源消费结构，楼层能耗对比，重点耗能设备分析等多种分析方式，报表分析可以帮助物业管理人员计算特定房间或人均能耗，实现自主能源审计管理。报表可以自动生成，按实际需要实现手动或自动打印，供调度和运行管理人员使用。",
      },
      data3: {
        title: "报警管理",
        list: [
          {
            title: "设备报警",
            text: "重要能耗设备的运行状态异常报警。",
            img: require("../../../assets/assets/buildingImg/icon1.png"),
          },
          {
            title: "环境质量报警",
            text: "空气质量，温度，湿度等异常报警。",
            img: require("../../../assets/assets/buildingImg/icon2.png"),
          },
          {
            title: "电源故障报警",
            text: "设备电源故障，ups断电报警。",
            img: require("../../../assets/assets/buildingImg/icon3.png"),
          },
          {
            title: "网络故障报警",
            text: "设备通讯及网络故障等异常报警。",
            img: require("../../../assets/assets/buildingImg/icon4.png"),
          },
          {
            title: "报警级别设定",
            text: "基于事件的报警，报警分组管理，报警优先级管理。",
            img: require("../../../assets/assets/buildingImg/icon5.png"),
          },
        ],
      },
      data4: {
        title: "设备管理",
        text: "能源管理系统的对象覆盖楼宇的各种大型能源设施，通过对能源设备的运行、异常、故障和事故状态实时监视和记录。通过技改和加强维护，指导维护保养工作，提高能源设备效率，实现能源设备闭环管理。",
        img: require("../../../assets/assets/buildingImg/img7.png"),
      },
    };
  },
  created() {
    this.id = this.$route.query.buildingId;
    const i = this.$route.query.buildingId;
    // eslint-disable-next-line eqeqeq
    if (i == 0) {
      this.data = this.data1;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 1) {
      this.data = this.data2;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 2) {
      this.data = this.data3;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 3) {
      this.data = this.data4;
    }
  },
  methods: {
    go(id) {
      this.$router.push({
        path: "/m_buildingDetail",
        query: {
          buildingId: id,
        },
      });
      this.id = id;
      if (id == 0) {
        this.data = this.data1;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 1) {
        this.data = this.data2;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 2) {
        this.data = this.data3;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 3) {
        this.data = this.data4;
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -27px;
  box-sizing: border-box;
  padding-bottom: 10px;
  > h1 {
    padding-left: 10px;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    padding-top: 70px;
  }
  P {
    padding-left: 10px;
    text-indent: 2em;
    width: calc(100% - 20px);
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 28px;
    color: #666666;
    margin: 20px 0;
  }
  > img {
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 20px);
    margin-bottom: 10px;
  }
  .more {
    margin: 0 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
    border-top: 1px solid #d5d5d5;
    padding-top: 20px;
  }
  .moreScene {
    padding-left: 10px;
    width: 240px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #365ef1;
    margin-top: 10px;
  }
  .call {
    padding-top: 15px;
  }
  .callPolice {
    display: flex;
    align-items: center;
    margin: 10px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgb(228, 227, 227);

    > img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        margin: 10px 0;
      }
      > div {
        font-size: 12px;
        font-weight: normal;
        color: #888888;
        margin: 10px 0;
      }
    }
  }
}
</style>
